<script>
import moment from 'moment';
import Layout from "../layouts/main";
import appConfig from "@/app.config";
import UserService from '@/services/user';

moment.locale('pt-BR');

export default {
  page: {
    title: "Dashboard",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout },
  data() {
    return {
      title: "Dashboard",
      userRole: '',
      stats: {},
      loaded: false,

      salesHistory: {
        series: [
          {
            name: "Total de Vendas",
            data: []
          },
        ],
        chartOptions: {
          chart: {
            stacked: true,
            toolbar: {
              show: false
            },
            zoom: {
              enabled: true
            }
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "15%",
              endingShape: "rounded"
            }
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: [
             
            ]
          },
          colors: ["#1c1798", "#f46a6a", "#34c38f"],
          legend: {
            position: "bottom"
          },
          fill: {
            opacity: 1
          }
        }
      }
    };
  },
  mounted() {
    this.$store.dispatch('USER_DATA').then((res) => {
      this.userRole = res.role;
    })

    UserService.getStats().then(res => {
      this.stats = res.data;

      const seriesTotal = [];
      const seriesLabels = [];

      this.loaded = true;

      this.stats.salesByMonth.forEach(sale => {
        seriesTotal.push(sale.total.toFixed(2));
        seriesLabels.push(sale.date);
      });

      this.salesHistory.series = [
        { name: 'Total de Vendas', data: seriesTotal },
      ]

      this.salesHistory.chartOptions = { 
        chart: {
          stacked: true,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: true
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "15%",
            endingShape: "rounded"
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: seriesLabels
        },
        colors: ["#34c38f", "#19bf4c", "#19bf4c"],
        legend: {
          position: "bottom"
        },
        fill: {
          opacity: 1
        }
      }
    })
  }
};
</script>

<template>
  <Layout>
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0 font-size-18">Dashboard</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->

    <div class="row" v-if="loaded">
      <div class="col-xl-12">
        <div class="row">
          <div :class="{'col-md-3': userRole === 'admin', 'col-md-4': userRole === 'store'}">
            <div class="card mini-stats-wid">
              <div class="card-body">
                <div class="media">
                  <div class="media-body">
                    <p class="text-muted">Total de Pedidos</p>
                    <h4 class="mb-0">{{stats.salesTotal ? stats.salesTotal.count : 0}}</h4>
                  </div>

                  <div
                    class="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center"
                  >
                    <span class="avatar-title">
                      <i class="bx bx-cart-alt font-size-24"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div :class="{'col-md-3': userRole === 'admin', 'col-md-4': userRole === 'store'}">
            <div class="card mini-stats-wid">
              <div class="card-body">
                <div class="media">
                  <div class="media-body">
                    <p class="text-muted">Total de Vendas</p>
                    <h4 class="mb-0">{{(stats.salesTotal ? stats.salesTotal.total : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}}</h4>
                  </div>

                  <div
                    class="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon"
                  >
                    <span class="avatar-title rounded-circle bg-primary">
                      <i class="bx bx-dollar font-size-24"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div :class="{'col-md-3': userRole === 'admin', 'col-md-4': userRole === 'store'}">
            <div class="card mini-stats-wid">
              <div class="card-body">
                <div class="media">
                  <div class="media-body">
                    <p class="text-muted">Ticket Médio</p>
                    <h4 class="mb-0">{{(stats.salesTotal ? stats.salesTotal.average : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}}</h4>
                  </div>
                  <div
                    class="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon"
                  >
                    <span class="avatar-title rounded-circle bg-primary">
                      <i class="bx bx-dollar-circle font-size-24"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3" v-if="userRole === 'admin'">
            <div class="card mini-stats-wid">
              <div class="card-body">
                <div class="media">
                  <div class="media-body">
                    <p class="text-muted">Clientes Cadastrados</p>
                    <h4 class="mb-0">{{stats.totalUsers}}</h4>
                  </div>
                  <div
                    class="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon"
                  >
                    <span class="avatar-title rounded-circle bg-primary">
                      <i class="bx bx-user font-size-24"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="loaded">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-2">Histórico de Vendas</h4>

            <apexchart
              class="apex-charts"
              type="bar"
              dir="ltr"
              height="284"
              :series="salesHistory.series"
              :options="salesHistory.chartOptions"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row" v-if="userRole === 'admin'">
      <div class="col-xl-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Principais Lojas</h4>

            <div class="text-center">
              <div class="mb-4">
                <i class="bx bx-store-alt text-primary display-4"></i>
              </div>
              <h3>Loja 1</h3>
              <p>99 pedidos</p>
            </div>

            <div class="table-responsive mt-4">
              <table class="table table-centered">
                <tbody>
                  <tr>
                    <td style="width: 140px">
                      <p class="mb-0">Loja 1</p>
                    </td>
                    <td style="width: 120px">
                      <h5 class="mb-0">99 pedidos</h5>
                    </td>
                    <td>
                      <div class="progress bg-transparent progress-sm">
                        <div
                          class="progress-bar bg-primary rounded"
                          role="progressbar"
                          style="width: 94%"
                          aria-valuenow="94"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p class="mb-0">Loja 2</p>
                    </td>
                    <td>
                      <h5 class="mb-0">87 pedidos</h5>
                    </td>
                    <td>
                      <div class="progress bg-transparent progress-sm">
                        <div
                          class="progress-bar bg-success rounded"
                          role="progressbar"
                          style="width: 82%"
                          aria-valuenow="82"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p class="mb-0">Loja 3</p>
                    </td>
                    <td>
                      <h5 class="mb-0">65 pedidos</h5>
                    </td>
                    <td>
                      <div class="progress bg-transparent progress-sm">
                        <div
                          class="progress-bar bg-warning rounded"
                          role="progressbar"
                          style="width: 70%"
                          aria-valuenow="70"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Produtos Mais Vendidos</h4>

            <div class="text-center">
              <div class="mb-4">
                <i class="bx bx-package text-primary display-4"></i>
              </div>
              <h3>1,456</h3>
              <p>Produto 1</p>
            </div>

            <div class="table-responsive mt-4">
              <table class="table table-centered">
                <tbody>
                  <tr>
                    <td style="width: 140px">
                      <p class="mb-0">Produto 1</p>
                    </td>
                    <td style="width: 120px">
                      <h5 class="mb-0">1,456</h5>
                    </td>
                    <td>
                      <div class="progress bg-transparent progress-sm">
                        <div
                          class="progress-bar bg-primary rounded"
                          role="progressbar"
                          style="width: 94%"
                          aria-valuenow="94"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p class="mb-0">Produto 2</p>
                    </td>
                    <td>
                      <h5 class="mb-0">1,123</h5>
                    </td>
                    <td>
                      <div class="progress bg-transparent progress-sm">
                        <div
                          class="progress-bar bg-success rounded"
                          role="progressbar"
                          style="width: 82%"
                          aria-valuenow="82"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p class="mb-0">Produto 3</p>
                    </td>
                    <td>
                      <h5 class="mb-0">1,026</h5>
                    </td>
                    <td>
                      <div class="progress bg-transparent progress-sm">
                        <div
                          class="progress-bar bg-warning rounded"
                          role="progressbar"
                          style="width: 70%"
                          aria-valuenow="70"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div class="row" v-if="userRole === 'store'">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Produtos Mais Vendidos</h4>

            <div class="text-center">
              <div class="mb-4">
                <i class="bx bx-package text-primary display-4"></i>
              </div>
              <h3>1,456</h3>
              <p>Produto 1</p>
            </div>

            <div class="table-responsive mt-4">
              <table class="table table-centered">
                <tbody>
                  <tr>
                    <td style="width: 140px">
                      <p class="mb-0">Produto 1</p>
                    </td>
                    <td style="width: 120px">
                      <h5 class="mb-0">1,456</h5>
                    </td>
                    <td>
                      <div class="progress bg-transparent progress-sm">
                        <div
                          class="progress-bar bg-primary rounded"
                          role="progressbar"
                          style="width: 94%"
                          aria-valuenow="94"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p class="mb-0">Produto 2</p>
                    </td>
                    <td>
                      <h5 class="mb-0">1,123</h5>
                    </td>
                    <td>
                      <div class="progress bg-transparent progress-sm">
                        <div
                          class="progress-bar bg-success rounded"
                          role="progressbar"
                          style="width: 82%"
                          aria-valuenow="82"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p class="mb-0">Produto 3</p>
                    </td>
                    <td>
                      <h5 class="mb-0">1,026</h5>
                    </td>
                    <td>
                      <div class="progress bg-transparent progress-sm">
                        <div
                          class="progress-bar bg-warning rounded"
                          role="progressbar"
                          style="width: 70%"
                          aria-valuenow="70"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </Layout>
</template>
